// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Belt_Array from "rescript/lib/es6/belt_Array.js";

var chars = "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789-_@$*";

function encode(number, pad) {
  var value = number / 64 | 0;
  var curr = number % 64;
  var encoded = chars.charAt(curr);
  while(value > 0) {
    curr = value % 64;
    value = value / 64 | 0;
    encoded = chars.charAt(curr) + encoded;
  };
  return encoded.padStart(pad, "A");
}

function encode1(number) {
  return chars.charAt(number);
}

function decode(encoded) {
  return Belt_Array.reduce(Belt_Array.map(encoded.split(""), (function (c) {
                    return chars.indexOf(c);
                  })), 0, (function (acc, v) {
                return (acc << 6) + v | 0;
              }));
}

export {
  chars ,
  encode ,
  encode1 ,
  decode ,
  
}
/* No side effect */
